// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-apie-mus-js": () => import("./../../../src/pages/apie-mus.js" /* webpackChunkName: "component---src-pages-apie-mus-js" */),
  "component---src-pages-autoserviso-paslaugos-js": () => import("./../../../src/pages/autoserviso-paslaugos.js" /* webpackChunkName: "component---src-pages-autoserviso-paslaugos-js" */),
  "component---src-pages-duk-js": () => import("./../../../src/pages/duk.js" /* webpackChunkName: "component---src-pages-duk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontaktai-js": () => import("./../../../src/pages/kontaktai.js" /* webpackChunkName: "component---src-pages-kontaktai-js" */),
  "component---src-pages-padangu-montavimas-js": () => import("./../../../src/pages/padangu-montavimas.js" /* webpackChunkName: "component---src-pages-padangu-montavimas-js" */),
  "component---src-pages-tepalu-keitimas-js": () => import("./../../../src/pages/tepalu-keitimas.js" /* webpackChunkName: "component---src-pages-tepalu-keitimas-js" */),
  "component---src-pages-variklio-diagnostika-js": () => import("./../../../src/pages/variklio-diagnostika.js" /* webpackChunkName: "component---src-pages-variklio-diagnostika-js" */),
  "component---src-pages-vaziuokles-remontas-js": () => import("./../../../src/pages/vaziuokles-remontas.js" /* webpackChunkName: "component---src-pages-vaziuokles-remontas-js" */)
}

